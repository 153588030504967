window.$ = window.jQuery = require("jquery")

require("../lib/bootstrap-3/js/bootstrap.js")
require("admin-lte")
require("datatables.net-bs")
require("select2")
require("bootstrap-colorpicker")

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import { Spanish } from "flatpickr/dist/l10n/es"
import axios from "axios"
import Clipboard from "clipboard"
import flatpickr from "flatpickr"
import LocationPicker from "location-picker"
import qs from "qs"
import React from "react"
import ReactDOM from "react-dom"
import RRuleGenerator from "react-rrule-generator"
import SimpleMDE from "simplemde"

// -----------------------------------------------------------------------------
// Stimulus
// -----------------------------------------------------------------------------

const application = Application.start()
const context = require.context("./controllers/admin", true, /\.js$/)
application.load(definitionsFromContext(context))

// -----------------------------------------------------------------------------
// On page loaded
// -----------------------------------------------------------------------------

document.addEventListener("DOMContentLoaded", () => {
    $("body").tooltip({ selector: "[data-toggle=tooltip]" })

    const query = qs.parse(window.location.search)
    const show = query.show || document.body.dataset.show

    if (show) {
        $(`#${show}-tab`).tab("show")
    }

    new Clipboard("[data-clipboard-target]")

    // Initialize date-time pickers

    flatpickr("[data-flatpickr]", {
        time_24hr: true,
        locale: Spanish,
        ...$("[data-flatpickr]").data("flatpickr"),
    })

    // Initialize data-tables

    $("[data-table]").each(function () {
        const config = $(this).data("table") || {}

        $(this).DataTable({
            responsive: true,
            language: {
                url: "//cdn.datatables.net/plug-ins/1.10.16/i18n/Czech.json",
            },
            ...config,
        })
    })

    // Initialize markdown editor.

    document.querySelectorAll(".markdown-editor").forEach(function (element) {
        new SimpleMDE({
            element,
            spellChecker: false,
        })
    })

    // Delete action.

    $("[data-action]").click(function (e) {
        e.preventDefault()
        const message = $(this).data("confirm")

        if (message && !confirm(message)) return

        $(this).children("form").submit()
    })

    // Location picker.

    if (document.querySelector("[data-location-picker]")) {
        const el = document.querySelector("[data-location-picker]")
        const confirm = document.querySelector(
            "[data-location-picker-confirm-button]"
        )
        const target = document.querySelector("[data-location-picker-target]")

        const [lat, lng] = target.value
            ? target.value.split(",").map(parseFloat)
            : [50.0815233, 14.4263542]

        const picker = new LocationPicker(el, {
            lat,
            lng,
        })

        confirm.addEventListener("click", () => {
            const { lat, lng } = picker.getMarkerPosition()
            target.value = `${lat},${lng}`
        })
    }

    // RRule form.

    if (document.querySelector("#rrule-form")) {
        ReactDOM.render(
            React.createElement(RRuleGenerator, {
                config: {
                    end: ["After", "On date"],
                },
                onChange: (rrule) => {
                    document.querySelector("#rrule-input").value = rrule
                },
            }),
            document.querySelector("#rrule-form")
        )
    }

    // Perform request of specified method by clicking link.

    document.querySelectorAll("[data-method]").forEach((link) => {
        link.addEventListener("click", async (e) => {
            e.preventDefault()

            const method = link.dataset.method
            const message =
                link.dataset.confirm || "Opravdu chcete smazat tuto položku?"

            if (method.toUpperCase() == "DELETE" && !confirm(message)) {
                return
            }

            await axios.post(link.href, {
                _method: method,
            })

            location.reload()
        })
    })

    document
        .querySelectorAll("[type=submit][data-confirm]")
        .forEach((button) => {
            button.addEventListener("click", (e) => {
                const message = button.dataset.confirm

                if (!confirm(message)) {
                    e.preventDefault()
                }
            })
        })

    $(".select2").select2()

    $("#clr-container").colorpicker({
        popover: false,
        inline: true,
        container: "#clr-container",
        useAlpha: false,
    })
})
